.div-cabecalho {
  background-color: rgb(255, 251, 251);
  position: fixed;
  opacity: 0.8;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  height: 70px;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: baseline;
}

.nav-item {
  color: #3E3E3E;
  text-decoration: none;
  transition: .3s ease-in;
  font-size: medium;
  padding: 10px;
}

.email {
  color: #3E3E3E;
  text-decoration: none;
  transition: .3s;
  font-size: medium;
  padding: 10px;
}

.nav-item:hover {
  font-weight: bold;
  color: #3E3E3E;
}

.h1-boas-vindas {
  margin-top: 8%;
  margin-left: 2%
}

.button-log-out {
  margin-left: 2%;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 5px;
  margin-left: 10%;
  width: 100px;
  background-color: #0084FF;
}

.button-log-out:hover {
  background-color: #0864b9;
}

.logo-header {
  width: 150px;
  padding-left: 2%;
}

.div-usuario-botao {
  display: flex;
  align-items: baseline;
  flex-direction: row;
  justify-content: space-between;
}

.menu-hamburguer {
  display: none;
}

.p-pag-embreve {
  padding-top: 100px;
}

@media only screen and ( max-width : 768px ) {
  .logo-header {
    width: 90px;
    padding-left: 0.5%;
    margin-bottom: 10%;
    margin-left: 3%;
  }

  .button-log-out {
    display: none;
  }

  .nav-item {
    display: none;
  }

  .email {
    display: none;
  }

  .menu-hamburguer {
    display: inline;
    padding-right: 20%;
  }

  .menu-hamburguer:target {
    background-color: purple;
  }
  h3 {
    display: none;
  }
}