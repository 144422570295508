.rodape-card {
  color: #614ea6;
  height: 35px;
}

.btn-detalhes {
  background-color: #614ea6;
  color: white;
}

.meus-canais {
  padding: 5%;
}

.card-text {
  max-height: 200px;
  overflow: hidden;
}

.img-cartao {
  max-height: 100px;
  min-width: 100%;
}

.principal-meus-canais-page {
  padding-top: 60px;
}

.cartao-box {
  background-image: url('https://hybri.io/assets/clients-background.png');
  background-position: top;
  border-radius: 10px;
  box-shadow: 3px 3px #9B30FF, -0.5em 0 0.4em rgb(94, 94, 238, 0.3);
  height: 19em;
  width: 20em;
  border: 1px solid black;
  margin: 2%;
  padding: 30px;
  font-size: 0.9em;
}