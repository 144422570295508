.div-autenticacao-id {
  padding-left: 5%;
  background-image: url('https://hybri.io/assets/clients-background.png');
  padding-top: 8%;
  padding-bottom: 30%;
}

.titulo-chat {
  padding-top: 3%;
  margin-bottom: -3%;
}

.div-mensagem {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  background-image: url('https://hybri.io/assets/clients-background.png');
  padding-top: 10%;
  padding-bottom: 30%;
}

.button-mensagem {
  margin-top: 20px;
  justify-content: center;
  color: white;
  background-color: #9B30FF;
  border-radius: 5px;
  width: 30%;
  border: 0px solid;
  height: 30px;
  margin-bottom: 20px;
}

.div-img-et {
  position: absolute;
  margin-left: 60%;
}

h3 {
  color: #3E3E3E;
}

h1 {
  color: #3E3E3E;
}

h2 {
  color: #3E3E3E;
}

.chat-box {
  background-image: url('https://hybri.io/assets/clients-background.png');
  background-size: cover;
  min-width: 1000px;
  max-height: 300px;
  min-height: 370px;
  overflow: scroll;
  padding: 3%;
}

.p-chat {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border: 0.6px solid rgb(88, 87, 87);
  border-radius: 30px;
}

.btn-chat {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.input-chat {
  height: 30px;
  width: 88%;
}

.input-btn-div {
  background-image: url('https://hybri.io/assets/clients-background.png');
}

@media only screen and ( max-width : 768px ) {
  .form-chat {
    min-width: 222px;
  }
  .chat-box {
    background-image: url('https://hybri.io/assets/clients-background.png');
    background-size: cover;
    min-width: 222px;
    max-height: 300px;
    min-height: 370px;
    overflow: scroll;
    padding: 3%;
  }

  .p-chat {
    width: 210px;
    height: 63px;
    font-size: small;
  }

  .div-chat {
    /* background-color: purple; */
    width: 70%;
  }

  .btn-chat {
    margin-bottom: 3%;
  }
}