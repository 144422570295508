.div-principal-Rodapé {
  color: #4a5568;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.logo-rodape {
  width: 60px;
  margin-right: 2%;
}