.principal-cadastro {
  padding: 5%;
  padding-top: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150%;
  position: relative;
  right: 20%;
}

.div-form-item {
  position: relative;
  left: 43%;
  display: flex;
  flex-direction: column;
  width: 180%;
}

.input-nome-canal {
  position: relative;
  width: 176%;
  position: relative;
  right: 58%;
  border-radius: 10px; 
  border: 0.8px solid rgb(204, 202, 202);
}

label {
  color: #4a5568;;
  padding: 3%;
  width: 180%;
  position: relative;
  right: 55%;
}

select {
  border: 0.8px solid rgb(204, 202, 202);
  background-color: white;
  border-radius: 10px;
  height: 40px;
  padding: 2%;
  width: 180%;
  position: relative;
  right: 55%;
}

option {
  border-radius: 10px;
  width: 100%;
  border: 0.8px solid rgb(204, 202, 202);
}

textarea {
  border-radius: 30px;
  width: 180%;
  position: relative;
  right: 55%;
  border: 0.8px solid rgb(204, 202, 202);
}

.upload-button {
  color: black;
  margin-top: 20px;
  justify-content: center;
  border-radius: 10px;
  border: 0px solid;
  height: 50px;
  position: relative;
  right: 57%;
}

.button-novo-canal {
  border-radius: 20px;
  width: 185%;
  margin-bottom: 30px;
  margin-top: 30%;
  position: relative;
  right: 70%;
  height: 50px;
  font-family: 'Montserrat', sans-serif;
}

@media only screen and ( max-width : 768px ) {
  form {
    margin-left: 20px;
    width: 170px;
    padding-top: 50px;
  }

  .menu-hamburguer {
    display: inline;
  }

  .div-cel-cadastro {
    width: 420px;
  }

  .div-cabecalho {
    width: 425px;
  }

  .button-novo-canal {
    position: relative;
    right: 85%;
  }

  .div-principal-Rodapé {
    color: #4a5568;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .logo-rodape {
    margin-bottom: 40px;
  }
}
