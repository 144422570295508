.img-banner {
  width: 100%;
  min-height: 300px;
  max-height: 350px;
}

.box-info {
  background-color: #9B30FF;
  border-radius: 10px;
  color: white;
  display: flex;
  display: flex;
  padding: 2%;
  flex-direction: column;
  align-items: center;
}

.principal-detalhes {
  background-image: url('https://hybri.io/assets/hero-background.png');
  background-position: top;
  background-size: cover;
  padding-top: 9%;
}

.todos-box {
  display: flex;
  margin-bottom: 10%;
}