.componentes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-evenly;
  margin-top: 10px;
}

img {
  width: 300px;
  display: flex;
  margin-top: 1%;
  margin-bottom: 1%;
}

.img-principal {
  position:absolute;
  margin-left: 440px;
}

.form-login {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cadastro-form {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.div-principal-login {
  border-radius: 10px;
  background-image: url('https://hybri.io/assets/clients-background.png');
  box-shadow: 3px 3px #9B30FF, -0.5em 0 0.4em rgb(94, 94, 238, 0.3);
  height: 30em;
  background-position: top;
  border: 1px solid black;
  margin-top: 20px;
  margin: 10%;
  padding: 35px;
  font-size: 0.9em;
}
.div-principal-cadastro {
  background-image: url('https://hybri.io/assets/clients-background.png');
  background-position: top;
  border-radius: 10px;
  box-shadow: 3px 3px #9B30FF, -0.5em 0 0.4em rgb(94, 94, 238, 0.3);
  height: 30em;
  border: 1px solid black;
  margin: 20%;
  padding: 35px;
  font-size: 0.9em;
}

input {
  margin: 3%;
  border-radius: 5px;
  padding: 10px;
}

button {
  margin-top: 20px;
  justify-content: center;
  color: white;
  background-color: #9B30FF;
  border-radius: 5px;
  width: 50%;
  border: 0px solid;
  height: 30px;
}

button:hover {
  background-color: #7b10df;
  transition: 0.5s;
  cursor: pointer;
}

.entrar {
  justify-content: center;
  color: white;
  background-color: #9B30FF;
  border-radius: 5px;
  width: 100px;
  border: 0px solid;
  height: 30px;
}

.google-fb-button {
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 5px;
  width: 200px;
  background-color: rgb(224, 205, 255);
}

.google-fb-button:hover {
  transition: 0.8s;
  color: white;
  border: 0px;
  background-color: #9B30FF;
}
.icon-google {
  padding-right: 2px;
}

.p-rec-senha {
  margin-top: 3%;
  text-decoration: none;
  color: purple;
}

.p-rec-senha:hover {
  color: rgb(55, 55, 238);
}

.span-user-logado {
  margin-left: 24%;
}

.span-user-redirecionar {
  margin-left: 13%;
}

.spin-div {
  margin-left: 50%;
}

@media only screen and ( max-width : 768px ) {
  img {
    width: 250px;
    display: flex;
    margin-top: 10%;
    margin-bottom: 1%;
  }

  .componentes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-evenly;
    margin-top: 40px;
  }
}