.principal-login-page {
  background-image: url('https://hybri.io/assets/hero-background.png');
  background-position: top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.principal {
  background-image: url('https://hybri.io/assets/hero-background.png');
  background-position: top;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-novo-canal {
  display: flex;
  flex-direction: column;
  margin-right: 50%;
  margin-top: 4%;
  width: 50%;
  align-items: flex-start;
  background-position: top;
}

.div-canais-existentes {
  background-position: top;
  background-image: url('https://hybri.io/assets/clients-background.png');
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.todos-canais {
  display: flex;
}

.h1-boas-vindas {
  width: 120%;
  font-size: 56px;
  color: black;
}

.h2-novo-canal {
  font-size: 28px;
  margin-left: 4%;
  width: 100%;
  color: #707070;
}

.h2-canal-existente {
  padding-top: 30px;
  font-size: 48px;
}

.sessao-canal {
  color: black;
  background-image: url('https://hybri.io/assets/clients-background.png');
  background-position: bottom;
  border-radius: 10px;
  box-shadow: 3px 3px #9B30FF, -0.5em 0 0.4em rgb(94, 94, 238, 0.3);
  height: 20em;
  border: 1px solid black;
  margin: 4%;
  min-width: 16em;
  padding: 30px;
  font-size: 0.9em;
  text-decoration: none;
}

.sessao-canal:hover {
  background-image: url('https://hybri.io/assets/hero-background.png');
  transition: 0.4s;
  cursor: pointer;
}

.img-principal {
  position: absolute;
  right: 0;
  width: 32%;
}
.novo-canal {
  font-size: 1em;
  margin-bottom: 40px;
  margin-left: 6%;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
}

.image-chat {
  padding-top: 15%;
  width: 180px;
}

.image-video {
  padding-top: 10%;
  width: 150px;
}

.usuario-nao-encontrado {
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.button-pag-login {
  width: 200%;
  margin-bottom: 200%;
  position: relative;
  right: 50%;
}

@media only screen and ( max-width : 768px ) {
  .todos-canais {
    display: flex;
    flex-direction: column;
  }

  .img-principal {
    display: none;
  }

  .div-canais-existentes {
    background-image: url('https://hybri.io/assets/clients-background.png');
    width: 100%;
    height: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .h1-boas-vindas {
    padding-top: 30%;
    margin-top: 10%;
    margin-left: 2%;
    width: 180%;
  }

  .h2-novo-canal {
    margin-top: 3%;
    margin-left: 3%;
    width: 180%;
  }

  .novo-canal {
    margin-left: 2%;
  }

  .div-canais-existentes {
    padding-top: 5%;
  }

  h2 {
    margin-left: 2%;
  }

  .principal {
    background-image: url('https://hybri.io/assets/hero-background.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}